/* eslint-disable import/no-cycle */
import { Badge, Icon, Tooltip, Typography } from '@material-ui/core';
import { FqType, SHORT_DATETIME_24H_FORMAT, DATETIME_24H_PLACEHOLDER } from '@rss/common';
import clsx from 'clsx';
import { get, isEmpty, isEqual, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { Comment } from '..';
import AssessmentFacilitySearch from '../../assessment/components/AssessmentFacilitySearch';
import BuildingSearch from '../location-search/BuildingSearch';
import PersonSearch from '../PersonSearch';
import ExternalFiles from './ExternalFiles';
import FqAddAnother from './FqAddAnother';
import FqAddressInput from './FqAddressInput';
import FqAttachments from './FqAttachments';
import FqCheckbox from './FqCheckbox';
import FqDatePicker from './FqDatePicker';
import FqDateTimePicker from './FqDateTimePicker';
import DepartmentSearch from './FqDepartmentSearch';
import FqDependent from './FqDependent';
import FqDropdown from './FqDropdown';
import FqGroup from './FqGroup';
import FqInputBox from './FqInputBox';
import FqLabel from './FqLabel';
import FqLibraryType from './FqLibraryType';
import FqMultiSelect from './FqMultiSelect';
import OccupationSearch from './FqOccupationSearch';
import PermitSearch from './FqPermitSearch';
import FqPermitSite from './FqPermitSite';
import FqRadio from './FqRadio';
import FqRecipients from './FqRecipients';
import FqRegulationType from './FqRegulationType';
import FqRichText from './FqRichText';
import FqSwitch from './FqSwitch';
import FqTimePicker from './FqTimePicker';
import { displayLabel, getFirstValue, hasErrors, QUESTION_VARIANT } from './Helper';

const FqQuestionFactory = ({
  attachmentUrl,
  autoFocus,
  canComment,
  canCommentPrivate,
  className,
  comments,
  configuration,
  privateComments,
  delay,
  disabled = false,
  inputErrors,
  isPrintView,
  labelVariant,
  nestedErrors,
  onChange,
  onComment,
  onDeleteComment,
  parentResponses,
  parent,
  propagateInvalid,
  question,
  shouldValidate,
  validators,
  value,
  values, // inspect-v2 will always use values, even for singular components (to allow for strict typing)
  variant,
  helperText,
  templateId,
}) => {
  const [questionObj, setQuestionObj] = useState(question);
  const [errors, setErrors] = useState(inputErrors || []);
  const [showComments, setShowComments] = useState({});

  const {
    addNew,
    disableFuture,
    disablePast,
    displayInRow,
    fullWidth,
    hintText,
    inputProps,
    validationProps,
    key,
    label,
    maxRows,
    options,
    placeholder,
    questions,
    required,
    rows,
    type,
    itemsDisplay,
    showCharCounter,
  } = questionObj;

  const enableComment =
    (canComment || canCommentPrivate) &&
    question.type !== FqType.GROUP &&
    question.type !== FqType.LABEL &&
    question.type !== FqType.DEPENDENT;

  const commentsLength = get(comments, `${question.key}.length`, 0) + get(privateComments, `${question.key}.length`, 0);
  const fqValue = value || values;
  const fqVariant = question?.variant || variant || QUESTION_VARIANT.STANDARD;

  const validate = useCallback(
    async (val) => {
      if (shouldValidate) {
        const validationErrors = await hasErrors(val, questionObj, validators, inputErrors);
        return setErrors(uniq([...validationErrors]));
      }

      return true;
    },
    [inputErrors, questionObj, validators, shouldValidate],
  );

  const handleChange = useCallback(
    async (val, selKey, shouldSave, message) => {
      if (!onChange) {
        return;
      }

      if (!isEqual(val, fqValue)) {
        if (selKey) {
          onChange({ [selKey]: val }, shouldSave, message);
        } else {
          onChange(val, shouldSave, message);
        }
      }
      validate(val);
    },
    [fqValue, onChange, validate],
  );

  useEffect(() => {
    if (shouldValidate) {
      validate(value);
    }
  }, [validate, value, shouldValidate]);

  useEffect(() => {
    if (inputErrors) {
      setErrors(inputErrors);
    }
  }, [inputErrors]);

  useEffect(() => {
    const inputPropsObj = { ...(question?.inputProps || {}) };
    if (inputPropsObj.type === 'number' && !isEmpty(validationProps)) {
      const { maxNumber, maxNumberKey, minNumber, minNumberKey } = validationProps;
      if (parentResponses[maxNumberKey] && Number(parentResponses[maxNumberKey])) {
        inputPropsObj.max = Number(parentResponses[maxNumberKey]);
      } else if (Number(maxNumber)) {
        inputPropsObj.max = Number(maxNumber);
      }

      if (parentResponses[minNumberKey] && Number(parentResponses[minNumberKey])) {
        inputPropsObj.min = Number(parentResponses[minNumberKey]);
      } else if (Number(minNumber)) {
        inputPropsObj.max = Number(minNumber);
      }
    }
    setQuestionObj({ ...question, inputProps: inputPropsObj });
  }, [question, parentResponses, validationProps]);

  const handleComment = (commentKey, commentValue, isPrivate) => {
    if (commentKey) {
      onComment({ [commentKey]: commentValue }, isPrivate);
    } else {
      onComment(commentValue, isPrivate);
    }
  };

  const handleDeleteComment = (commentKey, commentValue, isPrivate) => {
    if (commentKey) {
      onDeleteComment({ [commentKey]: commentValue }, isPrivate);
    } else {
      onDeleteComment(commentValue, isPrivate);
    }
  };

  const toggleComments = (questionKey) => {
    setShowComments({ ...showComments, [questionKey]: !showComments[questionKey] });
  };

  const showLabel = labelVariant === 'long' && ![FqType.LABEL].includes(question.type) && displayLabel(question);
  const { show24HourTime } = configuration;

  return (
    <div onBlur={() => validate(fqValue)} className={className}>
      {(showLabel || enableComment) && (
        <div className={clsx('flex')}>
          {showLabel && (
            <div className="flex-grow self-center">
              <FqLabel question={question} variant={fqVariant} disabled={disabled} />
            </div>
          )}
          {enableComment && (
            <div className={clsx('flex w-full px-16', commentsLength ? 'pt-5' : 'pt-0')}>
              {!!commentsLength && (
                <Badge
                  className="float-right ml-auto  cursor-pointer"
                  color="secondary"
                  badgeContent={[...(comments[question.key] || []), ...(privateComments[question.key] || [])].length}
                  onClick={() => toggleComments(question.key)}>
                  <Tooltip id="comment-icon" title="Add/Read Comments" placement="right">
                    <Icon color="primary">chat</Icon>
                  </Tooltip>
                </Badge>
              )}
              {(canComment || canCommentPrivate) && !commentsLength && (
                <Tooltip id="comment-icon-add" title="Add a comment" placement="right">
                  <Icon
                    className="float-right ml-auto flex cursor-pointer"
                    onClick={() => toggleComments(question.key)}
                    color="primary">
                    chat
                  </Icon>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      )}
      {(() => {
        switch (question.type) {
          case FqType.ADDRESS:
            return <FqAddressInput onChange={handleChange} questionKey={key} value={value ?? {}} disabled={disabled} />;
          case FqType.ATTACHMENT:
            return (
              <FqAttachments
                onChange={(val, qkey, message) => {
                  const SHOULD_SAVE = true;
                  handleChange(val, qkey, SHOULD_SAVE, message);
                }}
                key={key}
                questionKey={key}
                label={label}
                value={value || []}
                optional={!required}
                error={errors.length > 0}
                disabled={disabled}
                variant={fqVariant}
                attachmentUrl={attachmentUrl}
              />
            );
          case FqType.ADD_ANOTHER:
            return (
              <FqAddAnother
                onChange={(v, k) => handleChange(v, k)}
                question={question}
                values={fqValue ?? []}
                questionKey={key}
                disabled={disabled}
                shouldValidate={shouldValidate}
                variant={fqVariant}
                labelVariant={labelVariant}
              />
            );
          case FqType.REGULATION_SEARCH:
            return (
              <FqRegulationType
                disabled={disabled}
                fqVariant={fqVariant}
                key={key}
                label={label}
                labelVariant={labelVariant}
                onChange={handleChange}
                question={question}
                shouldValidate={shouldValidate}
                values={fqValue}
              />
            );
          case FqType.FACILITY_SEARCH:
            return (
              <AssessmentFacilitySearch
                disabled={disabled}
                fqVariant={fqVariant}
                key={key}
                labelVariant={labelVariant}
                onChange={handleChange}
                question={question}
                values={fqValue}
                templateId={templateId}
              />
            );
          case FqType.BUILDING_SEARCH:
            return (
              <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
                <BuildingSearch
                  id={key}
                  onSelect={(building) => handleChange(building, key)}
                  onClear={(e) => {
                    if (e?.target && !e.target.value) {
                      handleChange(null, key);
                    }
                  }}
                  question={question}
                  value={value}
                  label={label}
                  labelVariant={labelVariant}
                  isDisabled={disabled}
                  hintText={hintText}
                  helperText={helperText}
                />
              </div>
            );
          case FqType.OCCUPATION_SEARCH:
            return (
              <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
                <OccupationSearch
                  id={key}
                  onSelect={(occupation) => handleChange(occupation, key)}
                  onClear={(e) => {
                    if (e?.target && !e.target.value) {
                      handleChange(null, key);
                    }
                  }}
                  question={question}
                  value={value}
                  label={label}
                  labelVariant={labelVariant}
                  hintText={hintText}
                />
              </div>
            );
          case FqType.PERMIT_SEARCH:
            return (
              <PermitSearch
                id={key}
                onSelect={(occupation) => handleChange(occupation, key)}
                onClear={(e) => {
                  if (e?.target && !e.target.value) {
                    handleChange(null, key);
                  }
                }}
                disabled={disabled}
                question={question}
                questions={questions}
                parentResponses={parentResponses}
                value={value}
                label={label}
                labelVariant={labelVariant}
                hintText={hintText}
                error={errors.length > 0}
              />
            );
          case FqType.DEPARTMENT_SEARCH:
            return (
              <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
                <DepartmentSearch
                  autoFocus={autoFocus}
                  disabled={disabled}
                  error={errors.length > 0}
                  hintText={hintText}
                  label={label}
                  labelVariant={labelVariant}
                  onChange={handleChange}
                  onClear={(e) => {
                    if (e?.target && !e.target.value) {
                      handleChange(null, key);
                    }
                  }}
                  options={options}
                  placeholder={placeholder}
                  questionKey={key}
                  required={required}
                  value={value}
                  valueType={question.valueType}
                />
              </div>
            );
          case FqType.CHECKBOX:
            return (
              <FqCheckbox
                onChange={handleChange}
                key={key}
                questionKey={key}
                options={options}
                value={fqValue ?? []}
                error={errors.length > 0}
                disabled={disabled}
                label={label}
                labelVariant={labelVariant}
                variant={fqVariant}
                required={required}
                itemsDisplay={itemsDisplay}
              />
            );
          case FqType.DEPENDENT:
            return (
              <FqDependent
                onChange={(v, k) => handleChange(v, k)}
                label={label}
                labelVariant={labelVariant}
                question={question}
                value={fqValue ?? {}}
                parentResponses={parentResponses}
                error={errors.length > 0}
                disabled={disabled}
                onComment={(comment, isPrivate) => handleComment(question.key, comment, isPrivate)}
                onDeleteComment={(comment, isPrivate) => handleDeleteComment(question.key, comment, isPrivate)}
                comments={get(comments, question.key, [])}
                shouldValidate={shouldValidate}
                variant={fqVariant}
                nestedErrors={nestedErrors}
              />
            );
          case FqType.DROPDOWN:
            return (
              <FqDropdown
                onChange={handleChange}
                autoFocus={autoFocus}
                key={key}
                questionKey={key}
                label={label}
                labelVariant={labelVariant}
                options={options}
                hintText={hintText}
                value={fqValue ?? {}}
                required={required}
                error={errors.length > 0}
                disabled={disabled}
                valueType={question.valueType}
                variant={fqVariant}
                placeholder={placeholder}
              />
            );
          case FqType.DATEPICKER:
            return (
              <FqDatePicker
                autoFocus={autoFocus}
                dateFormat={question.dateFormat}
                disableFuture={disableFuture}
                disablePast={disablePast}
                disabled={disabled}
                fullWidth={fullWidth}
                error={errors.length > 0}
                key={key}
                label={label}
                labelVariant={question.labelVariant || labelVariant}
                maxDate={parentResponses[validationProps?.maxDateKey] || question.maxDate || null}
                maxDateMessage={validationProps?.maxDateMessage}
                minDate={
                  parentResponses?.minDate || parentResponses[validationProps?.minDateKey] || question.minDate || null
                }
                minDateMessage={validationProps?.minDateMessage}
                onChange={handleChange}
                propagateInvalid={propagateInvalid}
                questionKey={key}
                required={required}
                value={getFirstValue(fqValue)}
                variant={fqVariant}
              />
            );
          case FqType.TIMEPICKER:
            return (
              <FqTimePicker
                ampm={show24HourTime ? !show24HourTime : question.ampm}
                autoFocus={autoFocus}
                disabled={disabled}
                error={errors.length > 0}
                fullWidth={fullWidth}
                label={label}
                labelVariant={labelVariant}
                minutesStep={question.minutesStep}
                onChange={handleChange}
                propagateInvalid={propagateInvalid}
                questionKey={key}
                required={required}
                value={getFirstValue(fqValue)}
                variant={fqVariant}
              />
            );
          case FqType.DATETIMEPICKER:
            return (
              <FqDateTimePicker
                ampm={show24HourTime ? !show24HourTime : question.ampm}
                onChange={handleChange}
                propagateInvalid={propagateInvalid}
                value={getFirstValue(fqValue)}
                autoFocus={autoFocus}
                dateFormat={show24HourTime ? SHORT_DATETIME_24H_FORMAT : question.dateFormat}
                disabled={disabled}
                disableFuture={disableFuture}
                disablePast={disablePast}
                error={errors.length > 0}
                fullWidth={fullWidth}
                key={key}
                label={label}
                labelVariant={labelVariant}
                maxDate={parentResponses[validationProps?.maxDateKey] || question.maxDate || null}
                maxDateMessage={validationProps?.maxDateMessage}
                minDate={parentResponses[validationProps?.minDateKey] || question.minDate || null}
                minDateMessage={validationProps?.minDateMessage}
                required={required}
                questionKey={key}
                variant={fqVariant}
              />
            );
          case FqType.GROUP:
            return (
              <FqGroup
                onChange={handleChange}
                questions={questions}
                required={required}
                label={label}
                labelVariant={labelVariant}
                value={fqValue ?? {}}
                groupKey={key}
                disabled={disabled}
                onComment={(comment, isPrivate) => handleComment(question.key, comment, isPrivate)}
                onDeleteComment={(comment, isPrivate) => handleDeleteComment(question.key, comment, isPrivate)}
                comments={get(comments, question.key, [])}
                privateComments={get(privateComments, question.key, [])}
                canComment={canComment}
                canCommentPrivate={canCommentPrivate}
                shouldValidate={shouldValidate}
                variant={fqVariant}
              />
            );
          case FqType.INPUT:
          case FqType.TEXTAREA:
            return (
              <FqInputBox
                autoFocus={autoFocus}
                disabled={disabled}
                error={errors.length > 0}
                fqType={type}
                key={key}
                hintText={hintText}
                inputProps={inputProps}
                isPrintView={isPrintView}
                label={label}
                labelVariant={labelVariant}
                onChange={handleChange}
                onError={validate}
                delay={delay}
                placeholder={placeholder}
                questionKey={key}
                required={required}
                rows={rows}
                maxRows={maxRows}
                value={(values?.length ? values[0]?.value : value) ?? ''}
                variant={fqVariant}
                helperText={helperText}
                showCharCounter={showCharCounter}
              />
            );
          case FqType.RICHTEXT: {
            return (
              <FqRichText
                disabled={(question?.canEditQuestion && !question?.canEdit) || disabled}
                error={errors.length > 0}
                key={key}
                label={label}
                onChange={handleChange}
                placeholder={placeholder}
                questionKey={key}
                required={required}
                value={(values?.length ? values[0]?.value : value) ?? ''}
                variant={fqVariant}
                labelVariant={labelVariant}
              />
            );
          }
          case FqType.LIBRARYTYPE:
            return (
              <FqLibraryType
                onChange={handleChange}
                question={question}
                value={value ?? []}
                questionKey={key}
                libraryType={question.libraryType}
                showTagFilter={question.showTagFilter}
                showDetailsLink={Boolean(question.showDetailsLink)}
                followup={question.followup}
                followupLabel={question.followupLabel}
                label={label}
                placeHolder={placeholder}
                optional={!required}
                error={question.required}
                disabled={disabled}
                comments={comments}
                tagLabel={question.tagLabel}
                shouldValidate={shouldValidate}
                variant={fqVariant}
              />
            );
          case FqType.PERMIT_SITE:
            return (
              <FqPermitSite
                disabled={disabled}
                key={key}
                onChange={(v, k) => handleChange(v, k)}
                question={question}
                shouldValidate={shouldValidate}
                followup={question.followup}
                followupLabel={question.followupLabel}
                values={fqValue ?? []}
                permit={parent?.permit}
                variant={fqVariant}
                questionKey={key}
              />
            );
          case FqType.PERSON_SEARCH:
            return (
              <div className={`${variant === QUESTION_VARIANT.COMPACT ? 'm-8' : 'mx-24 mb-10'}`}>
                <PersonSearch
                  clearOnSelect={false}
                  disabled={disabled}
                  id={key}
                  label={label}
                  labelVariant={labelVariant}
                  minCharSearch={2}
                  onSelect={(person) => handleChange(person, key)}
                  onClear={(e) => {
                    if (e?.target && !e.target.value) {
                      handleChange(null, key);
                    }
                  }}
                  placeholder={placeholder}
                  required={required}
                  value={value}
                />
              </div>
            );
          case FqType.MULTISELECT:
            return (
              <FqMultiSelect
                disabled={disabled}
                error={errors.length > 0}
                label={label}
                labelVariant={labelVariant}
                limit={question.limit}
                value={(values?.length ? values : value) ?? []}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                valueType={question.valueType}
                questionKey={key}
                required={required}
                addNew={addNew}
                variant={fqVariant}
              />
            );
          case FqType.RADIO:
          case FqType.SCORE:
            return (
              <FqRadio
                disabled={disabled}
                error={errors.length > 0}
                hintText={hintText}
                key={key}
                label={label}
                labelVariant={labelVariant}
                onChange={handleChange}
                options={options}
                questionKey={key}
                required={required}
                row={displayInRow}
                value={(values?.length ? values[0]?.value : value) ?? ''}
                variant={fqVariant}
              />
            );
          case FqType.SWITCH:
            return (
              <FqSwitch
                disabled={disabled}
                onChange={handleChange}
                questionKey={key}
                label={label}
                value={(values?.length ? values[0]?.value : value) ?? ''}
                variant={fqVariant}
              />
            );
          case FqType.RECIPIENTS:
            return (
              <FqRecipients
                disabled={disabled}
                error={!isEmpty(errors || nestedErrors[question.key] || [])}
                label={label}
                labelVariant={labelVariant}
                limit={question.limit}
                value={(values?.length ? values : value) ?? []}
                onChange={handleChange}
                options={options}
                placeholder={placeholder}
                valueType={question.valueType}
                questionKey={key}
                required={required}
                addNew={addNew}
                variant={fqVariant}
              />
            );
          case FqType.LABEL:
            return (
              <div className="my-16">
                <FqLabel question={question} variant={fqVariant} />
              </div>
            );
          case FqType.EXTERNAL_FILE:
            return <ExternalFiles question={question} values={value ?? []} />;
          default:
            return null;
        }
      })()}
      {!disabled && !isEmpty(errors) && (
        <Typography
          className={`${variant === QUESTION_VARIANT.COMPACT ? '-mt-4 mb-8 ml-8' : '-mt-5 mb-16 ml-24'} `}
          color="error">
          {errors.join(' ')}
        </Typography>
      )}

      {!disabled && nestedErrors[question.key] && nestedErrors[question.key].length > 0 && (
        <Typography
          className={`${variant === QUESTION_VARIANT.COMPACT ? '-mt-4 mb-8 ml-8' : '-mt-5 mb-16 ml-24'} `}
          color="error">
          {nestedErrors[question.key].join('') || ''}
        </Typography>
      )}

      {enableComment && (
        <div>
          {showComments[question.key] && (
            <Comment
              onClose={() => toggleComments(question.key)}
              onCommentAction={(comment, isPrivate) => handleComment(question.key, comment, isPrivate)}
              comments={get(comments, question.key, [])}
              privateComments={get(privateComments, question.key, [])}
              onSave={(comment, isPrivate) => handleComment(question.key, comment, isPrivate)}
              onDelete={(comment, isPrivate) => handleDeleteComment(question.key, comment, isPrivate)}
              canComment={canComment}
              canCommentPrivate={canCommentPrivate}
            />
          )}
        </div>
      )}
    </div>
  );
};

FqQuestionFactory.propTypes = {
  attachmentUrl: PropTypes.string,
  autoFocus: PropTypes.bool,
  canComment: PropTypes.bool,
  canCommentPrivate: PropTypes.bool,
  className: PropTypes.string,
  comments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.any]),
  configuration: PropTypes.shape({ [PropTypes.string]: PropTypes.any }),
  privateComments: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object, PropTypes.any]),
  delay: PropTypes.bool,
  disabled: PropTypes.bool,
  helperText: PropTypes.string,
  inputErrors: PropTypes.arrayOf(PropTypes.string),
  isPrintView: PropTypes.bool,
  labelVariant: PropTypes.string,
  nestedErrors: PropTypes.objectOf(PropTypes.any),
  onChange: PropTypes.func,
  onComment: PropTypes.func,
  onDeleteComment: PropTypes.func,
  parentResponses: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  parent: PropTypes.objectOf(PropTypes.any),
  propagateInvalid: PropTypes.bool, // Incidates that date/time pickers should pass invalid dates to onChange
  question: PropTypes.objectOf(PropTypes.any).isRequired,
  shouldValidate: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.func),
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object, PropTypes.string, PropTypes.bool, PropTypes.any]),
  values: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.oneOf(Object.values(QUESTION_VARIANT)),
  templateId: PropTypes.string,
};

FqQuestionFactory.defaultProps = {
  attachmentUrl: '',
  autoFocus: false,
  canComment: false,
  canCommentPrivate: false,
  className: 'w-full',
  comments: {},
  configuration: {},
  privateComments: {},
  delay: true,
  disabled: false,
  helperText: '',
  inputErrors: null,
  isPrintView: false,
  labelVariant: 'default',
  nestedErrors: {},
  onChange: null,
  onComment: () => null,
  onDeleteComment: () => null,
  parentResponses: {},
  parent: {},
  propagateInvalid: false,
  shouldValidate: false,
  validators: [],
  value: null,
  values: [],
  variant: QUESTION_VARIANT.STANDARD,
  templateId: '',
};

export default React.memo(FqQuestionFactory);
